<template>
  <div class="edit_password">
    <div class="edit_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="form_box"
      >
        <el-form-item label="旧密码" prop="old_pwd">
          <el-input v-model="ruleForm.old_pwd" type="password"></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="new_pwd" style="margin-top: 40px">
          <el-input v-model="ruleForm.new_pwd" type="password"></el-input>
        </el-form-item>

        <el-form-item
          label="确认密码"
          prop="confirm_pwd"
          style="margin-top: 40px"
        >
          <el-input v-model="ruleForm.confirm_pwd" type="password"></el-input>
        </el-form-item>

        <div class="btn_box">
          <el-button type="info" @click="reset">重 置</el-button>
          <el-button type="success" style="margin-left: 32px" @click="confirm"
            >确 定</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { check_pwd } from '@/utils/validator.js'
import MD5 from 'md5'
import { EditPwdApi } from '@/api'

const checkPwd = function(form) {
  return (rule, value, cb) => {
    const reg = /^[0-9a-zA-Z_-]{1,24}$/
    if (!value) {
      return cb(new Error('请输入确认密码'))
    }
    // 限制输入内容
    if (!reg.test(value)) {
      return cb(new Error('密码仅可输入[0-9a-zA-Z_-]字符'))
    }
    // 大小写和数字
    if (!/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/[0-9]/.test(value)) {
      return cb(new Error('密码需同时包含大小写字母和数字'))
    }
    const newPwd = form.$data.ruleForm.new_pwd
    if (newPwd !== value) {
      return cb(new Error('两次输入的密码不一致'))
    }
    cb()
  }
}

export default {
  data() {
    return {
      ruleForm: {
        old_pwd: '',
        new_pwd: '',
        confirm_pwd: ''
      },
      rules: {
        old_pwd: check_pwd,
        new_pwd: check_pwd,
        confirm_pwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: checkPwd(this), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 重置
    reset() {
      try {
        this.$refs.ruleForm.resetFields()
      } catch (e) {}
    },

    // 确定
    confirm() {
      this.$refs.ruleForm.validate(async _ => {
        if (!_) return
        const params = {
          old_pwd: MD5(this.ruleForm.old_pwd),
          pwd: MD5(this.ruleForm.new_pwd)
        }
        const { ret, data, msg } = await EditPwdApi(params)
        if (ret === 8) {
          return this.$message.error('旧密码输入错误!')
        }
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('修改成功!')
        this.reset()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit_password {
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: #f9f8ff;
  display: flex;
  align-items: center;
  justify-content: center;

  .edit_box {
    width: 600px;
    background-color: #fff;
    height: 400px;
    padding: 60px 40px;
  }

  .btn_box {
    margin-top: 50px;
    text-align: center;
  }
}
</style>
